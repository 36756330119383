import React, { createContext, useCallback, useContext, useState } from 'react';
import { ProductCode } from '../../types/proceq';
import { useProceqAppConfigs } from '../../hooks/useConfig';
import { getLocalItem, saveLocalItem } from '../../utils/storageUtils';
import config from '../../config';

export enum ProductFeature {
    IGNORE_INVALID_MEASUREMENTS = 'ignore_invalid_measurements',
    ARCHIVE_MEASUREMENTS = 'archiveMeasurements',
    ARCHIVE_FOLDERS = 'archiveFolders',
    INSIGHTS_RELAUNCH_APP = 'insightsRelaunchApp',
    SPATIAL_DATA = 'spatialData',
    PROFOMETER_DOCX_SHORTLIST_IMAGE = 'profometer_docx_shortlist_image',
    PROFOMETER_STATISTICS = 'profometer_statistics', // EC-1916
    SHARE_UNSYNCED_GM_MEASUREMENTS = 'share_unsynced_gm_measurements',
}

export type DevFeatureConfigs = {
    [key in ProductFeature]?: boolean;
};

interface ProductContextState {
    product: ProductCode | undefined;
    setProduct: (product: ProductCode | undefined) => void;
    isFeatureEnabled: (feature: ProductFeature) => boolean;
    devFeatureConfigs: DevFeatureConfigs;
    toggleDevFeature: (feature: ProductFeature, enabled: boolean) => void;
}

const ProductContext = createContext<ProductContextState>({
    product: undefined,
    setProduct: () => {},
    isFeatureEnabled: () => false,
    toggleDevFeature: () => {},
    devFeatureConfigs: {},
});

const ProductContextProvider: React.FunctionComponent<React.PropsWithChildren> = (props) => {
    const [product, setProduct] = useState<ProductCode | undefined>(undefined);
    const proceqAppConfigs = useProceqAppConfigs();

    const [devFeatureConfigs, setDevFeatureConfigs] = useState<DevFeatureConfigs>(() => {
        if (config.APP_ENV === 'production') {
            return {};
        }
        const configsStr = getLocalItem('devFeatureConfig');
        return configsStr ? JSON.parse(configsStr) : {};
    });

    const toggleDevFeature = (featureName: ProductFeature, enabled: boolean) => {
        setDevFeatureConfigs((configs) => {
            const newFeatureConfigs = {
                ...configs,
                [featureName]: enabled ?? !configs[featureName],
            };
            saveLocalItem('devFeatureConfig', JSON.stringify(newFeatureConfigs));
            return newFeatureConfigs;
        });
    };

    const isFeatureEnabled = useCallback(
        (feature: ProductFeature) => {
            switch (feature) {
                case ProductFeature.ARCHIVE_MEASUREMENTS:
                case ProductFeature.ARCHIVE_FOLDERS: {
                    if (product && proceqAppConfigs[product]) {
                        return proceqAppConfigs[product]![`IS_ARCHIVING_ENABLED_${product.toUpperCase()}`] === 'YES';
                    }
                    return false;
                }
                default:
                    return devFeatureConfigs[feature] ?? false;
            }
        },
        [devFeatureConfigs, proceqAppConfigs, product]
    );

    return (
        <ProductContext.Provider value={{ product, setProduct, isFeatureEnabled, devFeatureConfigs, toggleDevFeature }}>
            {props.children}
        </ProductContext.Provider>
    );
};

export default ProductContextProvider;

export const useProductContext = () => {
    return useContext(ProductContext);
};
