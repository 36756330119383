import config from './config';

export const SUPPORT_EMAIL = 'support.insights@screeningeagle.com';
export const BAIDU_MAP_KEY = 'KYMWRrXQGZwKCr82MOjTGY7jmTbT6H6y';

export const CHINA_DOMAINS = new Set(config.CHINA_DOMAINS);

export const API_SCOPE = ['openid', 'email', 'profile', 'offline', 'sensor', 'sensor_common'].join(' ');

export const PUBLIC_FILES_ROUTE = `${config.EAGLE_CLOUD_DOMAIN}/files`;
