import React, { useMemo } from 'react';
import { differenceBy } from 'lodash';
import { browserUtils } from 'tds-common-fe';
import { LicenseProduct, LicenseTier } from '../../../types/license';
import styles from './UserLicense.styl';
import PlanFeatureList from '../Plan/PlanFeatureList';
import FormattedMessage from '../../../localization/FormatMessage';
import { ProductCode } from '../../../types/proceq';
import { getLabelFromGlobalDataKey } from '../../DataView/RegisteredInfo/helper';
import Button from '../../AnalyticsComponents/Button';
import Tooltip from '../../shared/Tooltip';
import analytics from '../../../analytics/firebaseAnalytics';
import { SubscriptionAction } from '../../../analytics/analyticsConstants';
import { getPublicFilePath } from '../../../utils/fileUtils';

type TierFeaturesProps = {
    product: ProductCode | LicenseProduct;
    tiers: LicenseTier[];
    isFreeTrial: boolean;
    currentTier?: LicenseTier;
    expirationDate?: string;
    onContactSales?: (tier: string) => void;
    isModalDisplay?: boolean; // for modal display the layout is slightly different
    binaryFileName?: string;
    isCurrentLicenseExpired?: boolean;
};

const TierFeatures: React.FunctionComponent<TierFeaturesProps> = (props) => {
    const {
        product,
        tiers,
        isFreeTrial,
        currentTier,
        expirationDate,
        onContactSales,
        isModalDisplay,
        binaryFileName,
        isCurrentLicenseExpired,
    } = props;
    const { tier: currentTierID, rank: currentTierRank = 0 } = currentTier ?? {};
    const freeTier = useMemo(() => {
        if (!tiers) return undefined;
        return tiers.find((tier) => tier.free);
    }, [tiers]);
    const nonFreeTiers = useMemo(() => tiers.filter((tier) => !tier.free), [tiers]);
    const productName = getLabelFromGlobalDataKey('availproductsfull', product);
    const isWindowsOS = browserUtils.getOSName(true).includes('windows');

    const getUniqueFeatures = (position: number, tier: LicenseTier) => {
        // if customerFeatureList is provided, use this instead of features
        if (tier.customerFeatureList) {
            return tier.customerFeatureList
                .split('\n')
                .map((text, index) => ({ code: index.toString(), description: text }));
        }
        let featuresIncluded = tier.features;
        if (nonFreeTiers[position - 1]) {
            featuresIncluded = differenceBy(tier.features, nonFreeTiers[position - 1].features, 'code');
        }
        return featuresIncluded;
    };

    const freeTrialTier =
        isFreeTrial && freeTier && expirationDate ? (
            <div className={styles.features_container_selected}>
                <div className={styles.tier_container}>
                    <div className={styles.tier_title}>{freeTier.name}</div>
                    <div>
                        <div className={styles.body2}>
                            <b>
                                <FormattedMessage id="License.Tier.FreeTrialAllFeatures" />
                            </b>
                        </div>
                        <div className={styles.body2}>
                            <FormattedMessage
                                id="License.Tier.FreeTrialKeepUsing"
                                values={{ productName, date: expirationDate }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        ) : null;

    return (
        <div className={isModalDisplay ? styles.tier_features_container_modal : styles.tier_features_container}>
            {freeTrialTier}
            {nonFreeTiers.map((tier, index) => {
                return (
                    <div
                        className={
                            currentTierID === tier.tier && !isModalDisplay
                                ? styles.features_container_selected
                                : styles.features_container_normal
                        }
                        key={tier.name}
                    >
                        <div className={styles.tier_container}>
                            <div className={styles.tier_title}>{tier.name}</div>
                            {onContactSales && (
                                <Button
                                    disabled={!isCurrentLicenseExpired && tier.rank <= currentTierRank}
                                    type="primary"
                                    onClick={() => {
                                        analytics.logSubscriptionEvent(SubscriptionAction.upgradeContactSales, product);
                                        onContactSales(tier.tier);
                                    }}
                                >
                                    <FormattedMessage
                                        id={
                                            !isCurrentLicenseExpired && tier.tier === currentTierID
                                                ? 'Profile.Subscriptions.CurrentPlan'
                                                : 'License.Contact.Sales'
                                        }
                                    />
                                </Button>
                            )}
                            <PlanFeatureList
                                className={styles.plan_list}
                                features={getUniqueFeatures(index, tier)}
                                columnCount={1}
                                includePreviousTier={nonFreeTiers[index - 1]?.name}
                            />
                            {!isModalDisplay && tier.requireDeviceID && binaryFileName && (
                                <Tooltip
                                    title={
                                        isWindowsOS ? undefined : (
                                            <FormattedMessage id="Profile.Subscriptions.WindowsOnly" />
                                        )
                                    }
                                >
                                    <div>
                                        <Button
                                            type="primary"
                                            ghost
                                            style={{ width: '100%', whiteSpace: 'pre-wrap', height: 'auto' }}
                                            disabled={!isWindowsOS}
                                            href={getPublicFilePath(binaryFileName, product as ProductCode)}
                                            onClick={() =>
                                                analytics.logSubscriptionEvent(
                                                    SubscriptionAction.downloadStandalone,
                                                    product
                                                )
                                            }
                                        >
                                            <FormattedMessage id="Profile.Subscriptions.DownloadStandalone" />
                                        </Button>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default TierFeatures;
