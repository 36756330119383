import { browserUtils } from 'tds-common-fe';
import React from 'react';
import { Button } from 'antd';
import StyledModal from '../../shared/StyledModal';
import FormattedMessage from '../../../localization/FormatMessage';
import styles from './LaunchAppModal.styl';
import { ProductCode } from '../../../types/proceq';
import analytics from '../../../analytics/firebaseAnalytics';
import config from '../../../config';
import { useProductBinaries } from '../../../queries/productQueries';
import appIcon from '../../../images/appIconGPRMountedUpload.png';
import useCurrentUserID from '../../../hooks/useCurrentUser';
import { getPublicFilePath } from '../../../utils/fileUtils';

interface LaunchAppModalProps {
    open: boolean;
    onClose: () => void;
    measurementID: string;
    product: ProductCode;
}

const LaunchAppModal: React.FunctionComponent<LaunchAppModalProps> = ({ open, onClose, measurementID, product }) => {
    const { data, isSuccess } = useProductBinaries(product);
    const userID = useCurrentUserID();

    const handleLaunchApp = () => {
        analytics.logLaunchApp(product, 'launch_app');
        window.open(`${config.GM_URL_SCHEME}://measurement/${measurementID}?userId=${userID}`, '_self');
        onClose();
    };

    const fileName =
        browserUtils.getOSName(true) === 'macos' ? data?.binaries?.macos_universal : data?.binaries?.['win_x86-64'];

    const appName = <FormattedMessage id={'App.AppName.GMUpload'} />;

    return (
        <StyledModal open={open} footer={null} onCancel={onClose}>
            <div className={styles.body_container}>
                <div>
                    <img src={appIcon} alt="appIcon" className={styles.logo} />
                </div>
                <div className={styles.subtitle3}>
                    <FormattedMessage id={'LaunchApp.Title'} values={{ appName }} />
                </div>
                <div>
                    <FormattedMessage
                        id={'LaunchApp.Body'}
                        values={{ appName: <div style={{ whiteSpace: 'nowrap' }}>{appName}</div> }}
                    />
                </div>
                <Button className={styles.launch_button} type="primary" onClick={handleLaunchApp}>
                    <FormattedMessage id="Import.LaunchApp" />
                </Button>
                {isSuccess && (
                    <div className={styles.caption}>
                        <FormattedMessage id="LaunchApp.Footer" values={{ appName }} />
                        <Button
                            className={styles.download_button}
                            type="link"
                            href={fileName ? getPublicFilePath(fileName, product) : undefined}
                            onClick={() => analytics.logLaunchApp(product, 'download_now')}
                        >
                            <FormattedMessage id={'LaunchApp.DownloadNow'} />
                        </Button>
                    </div>
                )}
            </div>
        </StyledModal>
    );
};

export default LaunchAppModal;
