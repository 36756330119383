import { ProductCode } from '../types/proceq';
import { BinaryFile } from '../types/binaryFile';
import { PUBLIC_FILES_ROUTE } from '../constants';

// use fetch directly as it's not a backend API, no need to check for preflight OPTIONS
export const getBinaries = async (productCode: ProductCode) => {
    const url = `${PUBLIC_FILES_ROUTE}/${productCode.toLowerCase()}/version.json`;
    const versionInfo = await fetch(url, { cache: 'no-cache' });
    return (await versionInfo.json()) as BinaryFile;
};
